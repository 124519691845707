<template>
  <div element-loading-background="#6a4c4185" class="footer">
    <div class="main">
      <div class="logo">{{ name }}</div>
      <div class="left">
        <div class="dropdown">
          <div class="dropdown-button">
            <el-avatar size="small" :src="value" />
          </div>
          <div class="dropdown-content">
            <div class="langlist">
              <el-avatar v-for="(item, index) in cities" :key="index" size="small" :src="item.img" @click="switchlanguage(item.img, item.lang)" />
            </div>
          </div>
        </div>
        <w3m-button class="button" size="sm" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { modal } from "@/api/web3ModalConfig";
import { useWeb3ModalAccount } from "@web3modal/ethers/vue";
import { useStore } from "vuex";
import { watch, ref, onMounted, getCurrentInstance } from "vue";
const { address, chainId, isConnected } = useWeb3ModalAccount();
const { proxy } = getCurrentInstance();
const value = ref("/icons/English.png");
const cities = [
  {
    lang: "en",
    img: "/icons/English.png",
  },
  {
    lang: "fr",
    img: "/icons/Français.png",
  },
  {
    lang: "kr",
    img: "/icons/Konglish.png",
  },
  {
    lang: "zh",
    img: "/icons/zh.png",
  },
  {
    lang: "es",
    img: "/icons/Spanish.png",
  },
  {
    lang: "tw",
    img: "/icons/tw.png",
  },
  {
    lang: "th",
    img: "/icons/Thai.png",
  },
];
const store = useStore();
const name = ref("DEXC.PRO");

store.commit("Whethertolink", isConnected.value);

const switchlanguage = async (img, lang) => {
  try {
    value.value = img;
    proxy.$i18n.locale = lang; // 更改语言
  } catch (error) {
    console.log(error);
  }
};
onMounted(() => {
  const userLanguage = navigator.language || navigator.languages[0];
  if (userLanguage.includes("zh")) {
    switchlanguage("/icons/zh.png", "zh");
  } else {
    switchlanguage("/icons/English.png", "en");
  }
});
watch(isConnected, (newVal, oldVal) => {
  store.commit("Whethertolink", isConnected.value);
});
watch(address, (newVal, oldVal) => {
  store.commit("setSinghash", null);
  store.commit("setSingtext", null);
  store.commit("setVerify", false);
});
</script>
<style scoped>
/*  */
/* 容器样式 */
.dropdown {
  position: relative;
  display: inline-block;
}

/* 按钮样式 */
.dropdown-button {
  font-size: 0;
  background: #121331;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #33344e inset !important;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer; /* 鼠标悬停时显示手形光标 */
}

/* 下拉菜单内容 */
.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
}

.dropdown:active .dropdown-content,
.dropdown:hover .dropdown-content {
  display: block;
}
.langlist {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px #33344e inset !important;
  background: #121331;
  border-radius: 4px;
  padding: 5px;
  gap: 8px;
}

/*  */
.left {
  display: flex;
  flex-wrap: nowrap;

  align-items: center; /* 垂直居中 */
  gap: 10px;
}
.logo {
  font-weight: bold;
}
.footer {
  display: flex;
  width: 100vw;
  height: 7vh;
  top: 0;
  background-color: #040726;
  z-index: 99;
  align-items: center; /* 垂直居中 */

  /* opacity: 0.3; */
}
.footer .main {
  width: 95vw;
  color: aliceblue !important;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; /* 左右两边分布 */
  align-items: center; /* 上下居中 */
}
/* .button button[data-size='sm'] {
		background: floralwhite !important;
	} */
@media (min-width: 625px) {
  .footer {
    height: 72.1875px;
  }
  .footer .main {
    width: 80vw;
  }
}
/* 当屏幕宽度小于 600px 时 */
@media (max-width: 500px) {
  .left {
    gap: 0px;
  }
  .button {
    order: 1;
  }
  .dropdown {
    order: 2;
  }
}
</style>
<style>
@media (max-width: 500px) {
  :root {
    --wui-spacing-xs: 4px !important;
  }
}
button:disabled {
  color: #fff !important;
}

wui-flex > wui-text {
  color: aliceblue;
}
.el-loading-spinner .path {
  stroke: #6a4c41 !important;
}
</style>
