<template>
  <div class="search">
    <el-input v-model="token" @input="select" :spellcheck="false" :placeholder="$t('dex.PleaseEnterTokenAddressOrName')" />
    <div class="PopularTokenayout">
      <span style="font-size: 16px; font-weight: bold; color: #606266">{{ $t("dex.CommonTokens") }}</span>
      <el-scrollbar>
        <div class="Popularlist">
          <div v-for="(item, index) in tokenlist.slice(0, 3)" :key="index" @click="Selecttoken(item.address, item.symbol)" class="Populartoken">
            <el-avatar class="tokenlistlogo" :src="item.address ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + item.address.toLowerCase() + '.png' : ''" size="small" />
            <span class="symbol">{{ item.symbol }}</span>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <el-scrollbar height="400px">
      <div class="tokenboxlist">
        <div v-for="(item, index) in tokennew" :key="index" @click="Selecttoken(item.address, item.symbol)" class="tokenlist">
          <div class="tokeninfo">
            <el-avatar class="tokenlistlogo" :src="item.address ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + item.address.toLowerCase() + '.png' : ''" size="small" />
            <div class="tokenname">
              <span class="symbol">{{ item.symbol }}</span>
              <span v-if="!item.source" class="name">{{ item.name }}</span>
              <div v-else class="tokeninfo name">
                <span>{{ $t("dex.AddedByUser") }}</span
                ><el-icon><CaretRight /></el-icon> <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <el-icon class="icosvg"><Right /></el-icon>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, getCurrentInstance } from "vue";
import { axiosInstance } from "@/api/post";
import { getprovider } from "@/api/wallet";
import { ethers } from "ethers";
import { useWeb3ModalAccount } from "@web3modal/ethers/vue";
const { address, chainId, isConnected } = useWeb3ModalAccount();
const emit = defineEmits(["sendData"]);
const { proxy } = getCurrentInstance();
const token = ref("");
const tokenlist = ref([]);
const tokennew = ref([]);

//
const greet = () => {
  token.value = "";

  select();
};
const getdata = async () => {
  try {
    if (isConnected.value) {
      let response = await axiosInstance.post("Query/tokenlist", { chain: chainId.value });
      response = response.data;

      if (response.code == 200) {
        let list = response.data;

        let data = await proxy.$indexedDB.getObjectsByChain(chainId.value);
        list = data.concat(list);
        tokenlist.value = list;
        tokennew.value = list;
      }
    }
  } catch (error) {
    proxy.$message.error(proxy.$t("dex.networkError"));
  }
};

const select = async () => {
  try {
    if (token.value.length < 42) {
      let Tokenlist = tokenlist.value.slice();
      tokennew.value = Tokenlist.filter((item) => {
        return item.symbol.toLowerCase().indexOf(token.value.toLowerCase()) > -1 || item.address.toLowerCase().indexOf(token.value.toLowerCase()) > -1;
      });
    } else if (token.value.length == 42) {
      if (!tokenlist.value.some((obj) => obj.address.toLowerCase() == token.value.toLowerCase())) {
        let tokenjson = await tokenname(token.value);
        if (tokenjson) {
          tokennew.value = [tokenjson];
          tokenlist.value.push(tokenjson);
          let data = await proxy.$indexedDB.getObjectByAddress(tokenjson.address);
          if (!data) {
            proxy.$indexedDB.addObject(tokenjson);
          }
        } else {
          tokennew.value = tokenlist.value.slice();
        }
      } else {
        let Tokenlist = tokenlist.value.slice();
        tokennew.value = Tokenlist.filter((item) => {
          return item.symbol.toLowerCase().indexOf(token.value.toLowerCase()) > -1 || item.address.toLowerCase().indexOf(token.value.toLowerCase()) > -1;
        });
      }
    } else {
      proxy.$message.error(proxy.$t("dex.InvalidTokenAddressLength"));
    }
  } catch (error) {
    console.log(error.message);
    proxy.$message.error(error.message);
  }
};
const tokenname = async (query) => {
  try {
    let abi = ["function symbol() external view returns (string memory)", "function name() external view returns (string memory)"];
    let contract = new ethers.Contract(query, abi, await getprovider());
    let name = await contract.name();
    let symbol = await contract.symbol();
    return { chain: chainId.value, address: query, name: name, symbol: symbol, source: true, eth: "0" };
  } catch (error) {
    proxy.$message.error(error.message);
    return false;
  }
};
defineExpose({
  greet,
});
const Selecttoken = (address, symbol) => {
  emit("sendData", address, symbol);
};

watch(chainId, (newChainId, oldChainId) => {
  getdata();
});
onMounted(() => {
  if (isConnected.value) {
    getdata();
  }
});
</script>
<style scoped>
.search {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.PopularTokenayout {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Popularlist {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
}
.Populartoken {
  box-shadow: 0 0 0 1px #33344e inset !important;
  padding: 5px 10px;
  text-transform: uppercase !important;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
}
.tokenboxlist {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tokenlist {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.tokeninfo {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: center;
}
.tokenname {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.name {
  font-size: 12px;
  color: #dadde2;
}
.symbol {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase !important;
  font-weight: bold;
}
</style>
<style>
.el-drawer__header {
  margin-bottom: 0px !important;
}
.icosvg {
  color: #fff !important;
}
</style>
