<template>
  <div class="Tokensearch">
    <el-dialog v-if="isDesktop" v-model="dialogTableVisible" :title="$t('dex.selecttoken')" :before-close="handleClose" width="500">
      <Search ref="childComp" @sendData="handleClose" />
    </el-dialog>
    <el-drawer v-else v-model="dialogTableVisible" direction="btt" size="620" :before-close="handleClose">
      <template #header>
        <span class="title">{{ $t("dex.selecttoken") }}</span>
      </template>
      <Search ref="childComp" @sendData="handleClose" />
    </el-drawer>
  </div>
</template>

<script>
import Search from "@/components/swap/search";
export default {
  name: "Swapbox",
  components: {
    Search,
  },
  data() {
    return {
      isDesktop: window.matchMedia("(min-width: 700px)").matches,
      dialogTableVisible: false,
      tokensel: "",
      // defaultchanid: '0',
    };
  },
  methods: {
    //
    handleResize(event) {
      this.isDesktop = event.matches;
    },
    boxswitch(ele) {
      this.tokensel = ele;
      this.dialogTableVisible = true;
      this.$nextTick(() => {
        if (this.$refs.childComp) {
          this.$refs.childComp.greet(); // 调用 greet 方法
        }
      });
    },
    handleClose(address = "", symbol = "") {
      this.$emit("sendData", address, symbol, this.tokensel);
      this.dialogTableVisible = false;
    },
  },
  mounted() {
    this.mediaQuery = window.matchMedia("(min-width: 700px)");
    this.mediaQuery.addEventListener("change", this.handleResize);
  },
  beforeDestroy() {
    this.mediaQuery.removeEventListener("change", this.handleResize);
  },
};
</script>
<style>
.Tokensearch .title {
  font-size: 16px;
  font-weight: bold;
  color: #ffff;
}
</style>
