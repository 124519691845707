<template>
  <div>
    <div class="box-card">
      <div class="swap">
        <div class="Linkwallet" v-if="!isConnected">
          <w3m-button size="sm" />
        </div>
        <el-icon class="swapicon" @click="tokenswitch">
          <DCaret />
        </el-icon>
        <div class="boxone">
          <div class="banspan">{{ $t("dex.Balance") + tokenaBalance }}</div>
          <div class="swaptoken">
            <el-input disabled v-model="tokenaquantity" />
            <div class="tokeninfo" @click="Selectatoken('a')">
              <el-avatar class="tokenlistlogo" size="small" :src="tokena ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + tokena.toLowerCase() + '.png' : ''" />
              <span>{{ tokenasymbol.toUpperCase() }}</span>
              <el-icon><ArrowRight v-if="!aswitch" /> <ArrowDown v-else /></el-icon>
            </div>
          </div>
        </div>
        <div class="boxtwo">
          <div class="banspan">{{ $t("dex.Balance") + tokenbBalance }}</div>
          <div class="swaptoken">
            <el-input disabled v-model="tokenbquantity" />
            <div class="tokeninfo" @click="Selectatoken('b')">
              <el-avatar class="tokenlistlogo" size="small" :src="tokenb ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + tokenb.toLowerCase() + '.png' : ''" />
              <span>{{ tokenbsymbol.toUpperCase() }}</span>
              <el-icon><ArrowRight v-if="!bswitch" /> <ArrowDown v-else /></el-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="Settings">
        <div class="Settinglist">
          <el-tag :type="Proportion == 0.25 ? 'danger' : 'success'" @click="(Proportion = 0.25), getExchangequantity()">25%</el-tag>
          <el-tag :type="Proportion == 0.5 ? 'danger' : 'success'" @click="(Proportion = 0.5), getExchangequantity()">50%</el-tag>
          <el-tag :type="Proportion == 1 ? 'danger' : 'success'" @click="(Proportion = 1), getExchangequantity()">100%</el-tag>
          <el-input style="height: 24px" size="small" :placeholder="$t('dex.PleaseEnterRemoveAmount')" @input="getlp" v-model="Exchangequantity"> </el-input>
        </div>
        <div class="removesetting">
          <el-switch v-if="NativeToken" v-model="Maincurrency">
            <template #active-action>
              <span class="custom-active-action">B</span>
            </template>
            <template #inactive-action>
              <span class="custom-inactive-action">T</span>
            </template>
          </el-switch>
          <el-icon class="Settingsicon" @click="switcslippage">
            <Tools />
          </el-icon>
        </div>
      </div>
      <!-- 查询LP 查询余额    -->
      <el-button v-if="!isConnected" :disabled="true" type="primary">{{ $t("dex.ConnectWallet") }}</el-button>
      <el-button v-else-if="!lpaddress" :disabled="true" type="primary">{{ $t("dex.PairDoesNotExist") }}</el-button>
      <el-button v-else-if="!Authorizeornot" :loading="loading" type="primary" @click="Authorize()">{{ $t("dex.Authorization") }}</el-button>
      <el-button v-else :loading="loading" :disabled="Exchangequantity > 0 ? false : true" @click="trade" type="primary">{{ $t("dex.removeliquidity") }}</el-button>
    </div>
    <Swapbox ref="swapbox" @sendData="switchswitching" />
    <slippage ref="slippage" />
  </div>
</template>

<script>
import { watch } from "vue";
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import Swapbox from "@/components/swap/box";
import slippage from "@/components/swap/Slippage";
import { axiosInstance } from "@/api/post";
import { getprovider, getEthBalance, Checktokenbalance, truncateDecimal } from "@/api/wallet";
import { useWeb3ModalAccount } from "@web3modal/ethers/vue";
import { ethers } from "ethers";
import Decimal from "decimal.js";

export default {
  name: "removeliquidity",
  components: {
    Swapbox,
    slippage,
  },
  setup() {
    // 使用 Composition API 访问全局属性
    const loading = ref(false);
    const { proxy } = getCurrentInstance();
    const tokenlist = ref([]);
    const aswitch = ref(false);
    const bswitch = ref(false);
    const disabled = ref(false);
    const tokena = ref("");
    const tokenb = ref("");
    const tokenasymbol = ref("");
    const tokenbsymbol = ref("");
    const tokenaBalance = ref("0");
    const tokenbBalance = ref("0");
    const tokenaquantity = ref("0");
    const tokenbquantity = ref("0");
    const Authorizeornot = ref(false);
    const Contract = ref(null);
    const PancakeRouter = ref(null);
    const Factory = ref(null);
    const tokenadecimals = ref(null);
    const tokenbdecimals = ref(null);
    const amountInMax = ref(null);
    const unitprice = ref(null);
    const path = ref([]);
    const circulationlist = ref([]);
    const acting = ref("0x135F132D5031AdEd52dDc94a8727cA8C619a9444");
    const Exchangequantity = ref(0);
    const Proportion = ref(1);
    const tokenarate = ref(0);
    const tokenbrate = ref(0);
    const lpaddress = ref(null);
    const lpbalance = ref(0);
    const Maincurrency = ref(false);
    const NativeToken = ref(false);

    const swapbox = ref(null); // 使用 ref 来引用 Swapbox 组件
    const slippage = ref(null);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const tokenswitch = () => {
      [tokena.value, tokenb.value] = [tokenb.value, tokena.value];
      if (tokena.value && tokenb.value && address.value) {
        Checkbalances();
        getlp();
      }
    };

    const Selectatoken = (ele) => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }
      if (ele === "a") {
        aswitch.value = true;
      } else {
        bswitch.value = true;
      }
      // 访问子组件的方法
      swapbox.value.boxswitch(ele);
    };

    const switcslippage = () => {
      slippage.value.boxswitch();
    };

    const switchswitching = (address, symbol, tokensel) => {
      if (tokensel === "a") {
        if (address && symbol) {
          tokena.value = address;
          tokenasymbol.value = symbol;
          Checkbalances();
          getlp();
        }
        aswitch.value = false;
      } else {
        if (address && symbol) {
          tokenb.value = address;
          tokenbsymbol.value = symbol;
          Checkbalances();
          getlp();
        }
        bswitch.value = false;
      }
    };

    const getdata = async () => {
      try {
        if (!isConnected.value) {
          chainId.value = 56;
        }
        let response = await axiosInstance.post("Query/tokenlist", { chain: chainId.value });
        response = response.data;
        if (response.code === 200) {
          let list = response.data;
          tokenasymbol.value = list[0] ? list[0].symbol : "";
          tokenbsymbol.value = list[1] ? list[1].symbol : "";
          tokena.value = list[0] ? list[0].address : "";
          tokenb.value = list[0] ? list[1].address : "";

          tokenlist.value = list;
          Checkbalances();
          getlp();
        }
      } catch (error) {
        proxy.$message.error(proxy.$t("dex.networkError"));
      }
    };

    const Checkbalances = async () => {
      try {
        if (!address.value) {
          return;
        }
        let data = await proxy.$indexedDB.getObjectsByChain(chainId.value);
        let tokenlistData = data.concat(tokenlist.value);

        for (let i = 0; i < tokenlistData.length; i++) {
          if (tokena.value === tokenlistData[i].address) {
            tokenasymbol.value = tokenlistData[i].symbol;
            if (tokenlistData[i].eth === "1") {
              tokenaBalance.value = truncateDecimal(await getEthBalance(address.value));
            } else {
              tokenaBalance.value = truncateDecimal(await Checktokenbalance(address.value, tokena.value));
            }
          }

          if (tokenb.value === tokenlistData[i].address) {
            tokenbsymbol.value = tokenlistData[i].symbol;
            if (tokenlistData[i].eth === "1") {
              tokenbBalance.value = truncateDecimal(await getEthBalance(address.value));
            } else {
              tokenbBalance.value = truncateDecimal(await Checktokenbalance(address.value, tokenb.value));
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getchanid = async () => {
      let response = await axiosInstance.post("Query/chain", { chain: Number(chainId.value) });
      response = response.data;
      if (response.code == 200) {
        Contract.value = response.data.contract;
        Factory.value = response.data.Factory;
        PancakeRouter.value = response.data.PancakeRouter;
      }
    };

    // 获取LP  并且计算单价
    const getlp = async () => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }

      if (!Contract.value) {
        await getchanid();
      }
      let abi = ["function getPair(address tokenA, address tokenB) view returns (address pair)"];

      let contract = new ethers.Contract(Factory.value, abi, getprovider());
      let Lpaddress = await contract.getPair(tokena.value, tokenb.value);
      if (Lpaddress != "0x0000000000000000000000000000000000000000") {
        // 计算数量
        let abi = ["function totalSupply() external view returns (uint)", "function token0() external view returns (address)", "function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)"];
        let contract = new ethers.Contract(Lpaddress, abi, getprovider());
        let Tokena = await contract.token0();
        let getReserves = await contract.getReserves();
        let totalSupply = ethers.formatUnits(await contract.totalSupply());
        abi = ["function rate() public view returns (uint)", "function getLiquidity(address tokenA,address tokenB,uint amountADesired,address swapV2Router) public view returns (uint)", "function getaost(address[] calldata _path,uint256 _autom,address _addr,address[] calldata circulation) public view returns (uint256, address[] memory, string[] memory)", "function decimals() view returns (uint8)"];
        contract = new ethers.Contract(tokena.value, abi, getprovider());
        tokenadecimals.value = await contract.decimals();
        contract = new ethers.Contract(tokenb.value, abi, getprovider());
        tokenbdecimals.value = await contract.decimals();

        lpbalance.value = truncateDecimal(await Checktokenbalance(address.value, Lpaddress));
        // 获取到了总量
        if (Exchangequantity.value == 0) {
          Exchangequantity.value = new Decimal(lpbalance.value).mul(new Decimal(Proportion.value)).toFixed(5);
        }
        // 计算一下比例

        if (tokena.value == Tokena) {
          tokenarate.value = new Decimal(ethers.formatUnits(getReserves[0], tokenadecimals.value)).div(new Decimal(totalSupply.toString())).toString();
          tokenbrate.value = new Decimal(ethers.formatUnits(getReserves[1], tokenbdecimals.value)).div(new Decimal(totalSupply.toString())).toString();
          tokenaquantity.value = new Decimal(tokenarate.value).mul(new Decimal(Exchangequantity.value)).toFixed(5);
          tokenbquantity.value = new Decimal(tokenbrate.value).mul(new Decimal(Exchangequantity.value)).toFixed(5);
        } else {
          tokenarate.value = new Decimal(ethers.formatUnits(getReserves[1], tokenadecimals.value)).div(new Decimal(totalSupply.toString())).toString();
          tokenbrate.value = new Decimal(ethers.formatUnits(getReserves[0], tokenbdecimals.value)).div(new Decimal(totalSupply.toString())).toString();
          tokenaquantity.value = new Decimal(tokenarate.value).mul(new Decimal(Exchangequantity.value)).toFixed(5);
          tokenbquantity.value = new Decimal(tokenbrate.value).mul(new Decimal(Exchangequantity.value)).toFixed(5);
        }
        isMaincurrency();
        lpaddress.value = Lpaddress;
        isAuthorize();
        // 检查授权
      } else {
        lpaddress.value = null;
        Exchangequantity.value = 0;
        tokenarate.value = 0;
        tokenbrate.value = 0;
        tokenaquantity.value = 0;
        tokenbquantity.value = 0;
      }
      // let abi = ['function decimals() view returns (uint8)', ' function allowance(address owner,address spender) external view returns (uint256)']
    };
    const isMaincurrency = async () => {
      let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
      let Tokenlist = data.concat(tokenlist.value);
      let continuei = false;
      for (let i = 0; i < Tokenlist.length; i++) {
        // tokena
        if (tokena.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
          continuei = true;
          Maincurrency.value = true;
          NativeToken.value = true;
        } else if (tokenb.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
          //tokenb ETH
          continuei = true;
          Maincurrency.value = true;
          NativeToken.value = true;
        }
      }
      if (!continuei) {
        Maincurrency.value = false;
        NativeToken.value = false;
      }
    };

    const getExchangequantity = async () => {
      // 计算金额
      if (lpbalance.value > 0) {
        Exchangequantity.value = new Decimal(lpbalance.value).mul(new Decimal(Proportion.value)).toFixed(5);
        getlp();
      }
    };
    // 只要授权
    // 检查授权
    const isAuthorize = async () => {
      try {
        // 先要判断是不是bnb

        // 这个要第一个input 输入了数字 才触发
        let abi = ["function decimals() view returns (uint8)", " function allowance(address owner,address spender) external view returns (uint256)"];
        let contract = new ethers.Contract(lpaddress.value, abi, getprovider());
        let allowance = await contract.allowance(address.value, PancakeRouter.value);
        // 获取当前代币精度
        let decimals = await contract.decimals();
        let balanceOf = ethers.formatUnits(allowance, decimals);

        if (balanceOf / 1 >= Exchangequantity.value / 1) {
          // 无需授权
          Authorizeornot.value = true;
        } else {
          // 需要授权
          Authorizeornot.value = false;
        }
      } catch (error) {
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };
    // 授权
    const Authorize = async () => {
      try {
        loading.value = true;
        let abi = ["function approve(address spender, uint256 amount) external returns (bool)"];
        let contract = new ethers.Contract(lpaddress.value, abi, await getprovider().getSigner());
        let tx = await contract.approve(PancakeRouter.value, ethers.MaxUint256);
        await tx.wait();
        loading.value = false;
        isAuthorize();
      } catch (error) {
        loading.value = false;
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };

    // 交易
    const trade = async () => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }
      if (Exchangequantity.value <= 0) {
        proxy.$message.error(proxy.$t("dex.PleaseEnterRemoveAmount"));
        return;
      }

      try {
        loading.value = true;
        let continuei = false;
        let abi = ["function removeLiquidity(address tokenA, address tokenB,uint liquidity,uint amountAMin,uint amountBMin,address to, uint deadline)", "function removeLiquidityETH(address token,uint liquidity,uint amountTokenMin,uint amountETHMin,address to,uint deadline)"];
        let contract = new ethers.Contract(PancakeRouter.value, abi, await getprovider().getSigner());
        // 计算代币A最小数量
        let amountAMin = new Decimal(tokenaquantity.value.toString()).sub(new Decimal(tokenaquantity.value.toString()).mul(new Decimal(slippage.value.form.Slippage.toString()).div("100"))).toFixed(parseInt(tokenadecimals.value));
        amountAMin = ethers.parseUnits(amountAMin, tokenadecimals.value);
        // 计算代币B最小数量
        let amountBMin = new Decimal(tokenbquantity.value.toString()).sub(new Decimal(tokenbquantity.value.toString()).mul(new Decimal(slippage.value.form.Slippage.toString()).div("100"))).toFixed(parseInt(tokenbdecimals.value));
        amountBMin = ethers.parseUnits(amountBMin, tokenbdecimals.value);

        let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
        let Tokenlist = data.concat(tokenlist.value);

        for (let i = 0; i < Tokenlist.length; i++) {
          // tokena
          if (tokena.value == Tokenlist[i].address && Tokenlist[i].eth == "1" && Maincurrency.value) {
            continuei = true;
            //tokena是 ETH
            let tx = await contract.removeLiquidityETH(tokenb.value, ethers.parseUnits(Exchangequantity.value), amountBMin, amountAMin, address.value, Math.floor(Date.now() / 1000) + 100);
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          } else if (tokenb.value == Tokenlist[i].address && Tokenlist[i].eth == "1" && Maincurrency.value) {
            //tokenb ETH
            continuei = true;
            let tx = await contract.removeLiquidityETH(tokena.value, ethers.parseUnits(Exchangequantity.value), amountAMin, amountBMin, address.value, Math.floor(Date.now() / 1000) + 100);
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          }
        }
        // 在这里处理不是eth的情况
        if (!continuei) {
          let tx = await contract.removeLiquidity(tokena.value, tokenb.value, ethers.parseUnits(Exchangequantity.value), amountAMin, amountBMin, address.value, Math.floor(Date.now() / 1000) + 100);
          await tx.wait();
          proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
        }

        loading.value = false;
      } catch (error) {
        loading.value = false;
        console.error(error.message);
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };

    watch(chainId, (newChainId, oldChainId) => {
      getdata();
      getchanid();
      // 在这里添加你的处理逻辑
    });
    onMounted(() => {
      if (isConnected.value) {
        getdata();
        getchanid();
      }
    });

    return {
      tokenlist,
      address,
      aswitch,
      bswitch,
      disabled,
      tokena,
      tokenb,
      tokenasymbol,
      tokenbsymbol,
      tokenaBalance,
      tokenbBalance,
      tokenaquantity,
      tokenbquantity,
      tokenswitch,
      Selectatoken,
      switcslippage,
      switchswitching,
      swapbox,
      slippage,
      chainId,
      isConnected,
      Exchangequantity,
      Proportion,
      getlp,
      getExchangequantity,
      lpaddress,
      Authorizeornot,
      Authorize,
      loading,
      trade,
      Maincurrency,
      NativeToken,
    };
  },
};
</script>

<style scoped>
.Settinglist {
  flex: 1;
  display: flex;

  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.el-tag {
  padding: 0 5px;
}
.swapinputbox {
  flex: 1;
}

.swap .boxone {
  background: #1249ec;
  border-radius: 10px;
  height: 100px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  /* font-size: 16px; */
}
.swap .boxtwo {
  background: #121331;
  border-radius: 10px;
  border: 1px solid #33344e;
  height: 100px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* font-size: 16px; */
}

.swap {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
}

.swaptoken {
  display: flex;
  flex-wrap: nowrap;
}
.tokeninfo {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
.banspan {
  padding: 0 11px;
  font-size: 15px;
  color: #ffff;
}
.Settings {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* justify-content: flex-end; */
  padding-bottom: 10px;
  align-items: center;
  gap: 15px;
}
.removesetting {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: center;
}
</style>
<style>
.swap .el-input__inner {
  --el-input-inner-height: none !important;
  font-size: 16px;
}
.swap .el-input {
  width: 60vw;
}
.swap .swapicon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: #040726;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  border: 4px solid #040726;
  transform: translateY(-50%);
  right: 50%;
  z-index: 2;
}
.box-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 95vw;
  gap: 15px;
}

@media (min-width: 600px) {
  .box-card {
    width: 600px !important;
  }
}
@media (min-width: 750px) {
  .swap .el-input {
    width: 450px !important;
  }
}
</style>
<style>
.Settingsicon {
  font-size: 20px !important;
  color: #fff !important;
}
.swap .boxone .el-input__wrapper {
  box-shadow: none !important;
  height: 40px !important;
  background-color: #1249ec !important;
}
.swap .boxtwo .el-input__wrapper {
  box-shadow: none !important;
  height: 40px !important;
  background-color: #121331 !important;
}
</style>
<!-- 使用签名(收款人(中继者地址 nonce)   存款 存款人地址(nonce)(代币)存储数量  ) -->
<!-- 取款  通过中继者地址后端取款  用户无需链接钱包    使用签名凭证(包含了nonce 收款人地址)   用户只需要填写凭证  收款地址  即可通过中继者取出款项-->
