<template>
  <div>
    <div class="box-card">
      <div class="swap">
        <div class="Linkwallet" v-if="!isConnected">
          <w3m-button size="sm" />
        </div>

        <el-icon class="swapicon" @click="tokenswitch">
          <DCaret />
        </el-icon>
        <div class="boxone">
          <div class="banspan">{{ $t("dex.Balance") + tokenaBalance }}</div>
          <div class="swaptoken">
            <el-input class="inputfont" :disabled="disabled" v-model="tokenaquantity" @input="isAuthorize" :placeholder="$t('dex.PleaseEnterSwapAmount')" />

            <div class="tokeninfo" @click="Selectatoken('a')">
              <el-avatar class="tokenlistlogo" size="small" :src="tokena ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + tokena.toLowerCase() + '.png' : ''" />
              <span>{{ tokenasymbol.toUpperCase() }}</span>
              <el-icon><ArrowRight v-if="!aswitch" /> <ArrowDown v-else /></el-icon>
            </div>
          </div>
        </div>
        <div class="boxtwo">
          <div class="banspan">{{ $t("dex.Balance") + tokenbBalance }}</div>
          <div class="swaptoken">
            <el-input class="inputfont" :disabled="disabled" v-model="tokenbquantity" :placeholder="$t('dex.PleaseEnterSwapAmount')" />

            <div class="tokeninfo" @click="Selectatoken('b')">
              <el-avatar class="tokenlistlogo" size="small" :src="tokenb ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + tokenb.toLowerCase() + '.png' : ''" />
              <span>{{ tokenbsymbol.toUpperCase() }}</span>
              <el-icon><ArrowRight v-if="!bswitch" /> <ArrowDown v-else /></el-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="Settings">
        <span class="unitpricefon">{{ unitprice }}</span>
        <el-icon class="Settingsicon" @click="switcslippage">
          <Tools />
        </el-icon>
      </div>
      <el-button v-if="!isConnected" :disabled="true" type="primary">{{ $t("dex.ConnectWallet") }}</el-button>
      <el-button v-else-if="tokenaquantity == 0" :disabled="true" type="primary">{{ $t("dex.PleaseEnterAmount") }}</el-button>
      <el-button v-else-if="tokenbquantity == 0" :disabled="true" type="primary">{{ $t("dex.InsufficientLiquidity") }}</el-button>
      <el-button v-else-if="tokenaBalance / 1 < tokenaquantity / 1" :disabled="true" type="primary">{{ $t("dex.InsufficientLiquidity") }}</el-button>
      <el-button v-else-if="!Authorizeornot" type="primary" :loading="loading" @click="Authorize">{{ $t("dex.Authorization") }}</el-button>
      <el-button v-else type="primary" :loading="loading" @click="trade">{{ $t("dex.Transaction") }}</el-button>
    </div>
    <Swapbox ref="swapbox" @sendData="switchswitching" :key="chainId" />
    <slippage ref="slippage" @sendData="getaost" />
  </div>
</template>

<script>
import { watch } from "vue";
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import Swapbox from "@/components/swap/box";
import slippage from "@/components/swap/Slippage";
import { axiosInstance } from "@/api/post";
import { ethers } from "ethers";
import { modal } from "@/api/web3ModalConfig";
import { getprovider, getEthBalance, Checktokenbalance, truncateDecimal } from "@/api/wallet";
import { useWeb3ModalAccount } from "@web3modal/ethers/vue";
import Decimal from "decimal.js";

export default {
  name: "swap",
  components: {
    Swapbox,
    slippage,
  },
  setup() {
    // 使用 Composition API 访问全局属性
    const loading = ref(false);
    const { proxy } = getCurrentInstance();
    const tokenlist = ref([]);
    const aswitch = ref(false);
    const bswitch = ref(false);
    const disabled = ref(false);
    const tokena = ref("");
    const tokenb = ref("");
    const tokenasymbol = ref("");
    const tokenbsymbol = ref("");
    const tokenaBalance = ref("0");
    const tokenbBalance = ref("0");
    const tokenaquantity = ref("0");
    const tokenbquantity = ref("0");
    const Authorizeornot = ref(false);
    const Contract = ref(null);
    const PancakeRouter = ref(null);
    const Factory = ref(null);
    const tokenadecimals = ref(null);
    const tokenbdecimals = ref(null);
    const amountInMax = ref(null);
    const unitprice = ref(null);
    const path = ref([]);
    const circulationlist = ref([]);
    const acting = ref("0x0000000000000000000000000000000000000000");

    const swapbox = ref(null); // 使用 ref 来引用 Swapbox 组件
    const slippage = ref(null);
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const tokenswitch = () => {
      [tokena.value, tokenb.value] = [tokenb.value, tokena.value];
      if (tokena.value && tokenb.value && address.value) {
        Checkbalances();
      }
    };

    const Selectatoken = (ele) => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }
      if (ele === "a") {
        aswitch.value = true;
      } else {
        bswitch.value = true;
      }
      // 访问子组件的方法
      swapbox.value.boxswitch(ele);
    };

    const switcslippage = () => {
      slippage.value.boxswitch();
    };

    const switchswitching = (address, symbol, tokensel) => {
      if (tokensel === "a") {
        if (address && symbol) {
          tokena.value = address;
          tokenasymbol.value = symbol;
          Checkbalances();
        }
        aswitch.value = false;
      } else {
        if (address && symbol) {
          tokenb.value = address;
          tokenbsymbol.value = symbol;
          Checkbalances();
        }
        bswitch.value = false;
      }
    };

    const getchanid = async () => {
      let response = await axiosInstance.post("Query/chain", { chain: Number(chainId.value) });
      response = response.data;
      if (response.code == 200) {
        Contract.value = response.data.contract;
        Factory.value = response.data.Factory;
        PancakeRouter.value = response.data.PancakeRouter;
      }
    };

    const getdata = async () => {
      try {
        if (!isConnected.value) {
          chainId.value = 56;
        }
        let response = await axiosInstance.post("Query/tokenlist", { chain: chainId.value });
        response = response.data;
        if (response.code === 200) {
          let list = response.data;
          circulationlist.value = [...list];
          tokenasymbol.value = list[0] ? list[0].symbol : "";
          tokenbsymbol.value = list[1] ? list[1].symbol : "";
          tokena.value = list[0] ? list[0].address : "";
          tokenb.value = list[0] ? list[1].address : "";
          tokenlist.value = list;
          Checkbalances();
        }
      } catch (error) {
        proxy.$message.error(proxy.$t("dex.networkError"));
      }
    };

    const Checkbalances = async () => {
      try {
        if (!address.value) {
          return;
        }
        loading.value = true;
        let data = await proxy.$indexedDB.getObjectsByChain(chainId.value);
        let tokenlistData = data.concat(tokenlist.value);

        for (let i = 0; i < tokenlistData.length; i++) {
          if (tokena.value === tokenlistData[i].address) {
            tokenasymbol.value = tokenlistData[i].symbol;
            if (tokenlistData[i].eth === "1") {
              tokenaBalance.value = truncateDecimal(await getEthBalance(address.value));
            } else {
              tokenaBalance.value = truncateDecimal(await Checktokenbalance(address.value, tokena.value));
            }
          }

          if (tokenb.value === tokenlistData[i].address) {
            tokenbsymbol.value = tokenlistData[i].symbol;
            if (tokenlistData[i].eth === "1") {
              tokenbBalance.value = truncateDecimal(await getEthBalance(address.value));
            } else {
              tokenbBalance.value = truncateDecimal(await Checktokenbalance(address.value, tokenb.value));
            }
          }
        }
        loading.value = false;
        isAuthorize();
      } catch (error) {
        console.error(error);
      }
    };

    // 检查授权
    const isAuthorize = async () => {
      try {
        if (!isConnected.value) {
          return;
        }
        if (!Contract.value) {
          await getchanid();
        }
        // 先要判断是不是bnb
        loading.value = true;
        let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
        let Tokenlist = data.concat(tokenlist.value);

        for (let i = 0; i < Tokenlist.length; i++) {
          if (tokena.value == Tokenlist[i].address) {
            if (Tokenlist[i].eth == "1") {
              Authorizeornot.value = true;
            } else {
              if (tokenaquantity.value > 0 && address.value) {
                // 这个要第一个input 输入了数字 才触发
                let abi = ["function decimals() view returns (uint8)", " function allowance(address owner,address spender) external view returns (uint256)"];
                let contract = new ethers.Contract(tokena.value, abi, getprovider());

                let allowance = await contract.allowance(address.value, Contract.value);
                // 获取当前代币精度
                let decimals = await contract.decimals();
                let balanceOf = ethers.formatUnits(allowance, decimals);

                if (balanceOf / 1 >= tokenaquantity.value / 1) {
                  // 无需授权
                  Authorizeornot.value = true;
                } else {
                  // 需要授权
                  Authorizeornot.value = false;
                }
              }
            }
          }
        }
        loading.value = false;
        getaost();
      } catch (error) {
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };
    // 查询交易路径
    const getaost = async () => {
      try {
        if (!isConnected.value) {
          proxy.$message.error(proxy.$t("dex.ConnectWallet"));
          return;
        }
        if (!Contract.value) {
          await getchanid();
        }
        loading.value = true;
        if (tokenaquantity.value > 0) {
          let abi = ["function rate() public view returns (uint)"];
          let contract = new ethers.Contract(Contract.value, abi, await getprovider());
          let handlingfee = await contract.rate();
          abi = ["function getaost(address[] calldata _path,uint256 _autom,address _addr,address[] calldata circulation) public view returns (uint256, address[] memory, string[] memory)", "function decimals() view returns (uint8)"];
          contract = new ethers.Contract(tokena.value, abi, getprovider());
          tokenadecimals.value = await contract.decimals();
          contract = new ethers.Contract(tokenb.value, abi, getprovider());
          tokenbdecimals.value = await contract.decimals();
          contract = new ethers.Contract(Contract.value, abi, await getprovider().getSigner());
          let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
          let Tokenlist = data.concat(tokenlist.value);

          let circulation = Tokenlist.map((token) => token.address.toLowerCase());
          // 判断token 是不是eth
          // 判断 tokenA 是不是主流币
          // 判断 tokenb 是不是主流币 这样才对
          let Tokenaquantity = tokenaquantity.value;
          let continuei = false;
          let b = false;
          for (let i = 0; i < Tokenlist.length; i++) {
            // tokena
            if (tokena.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
              Tokenaquantity = new Decimal(Tokenaquantity.toString()).sub(new Decimal(Tokenaquantity.toString()).mul(new Decimal(handlingfee.toString()).div("100"))).toFixed(parseInt(tokenadecimals.value));
              continuei = true;
            } else if (tokenb.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
              continuei = true;
              b = true;
            }
          }

          if (!continuei) {
            if (circulation.indexOf(tokena.value.toLowerCase()) != -1) {
              // tokena 是主流币 也是先扣
              Tokenaquantity = new Decimal(Tokenaquantity.toString()).sub(new Decimal(Tokenaquantity.toString()).mul(new Decimal(handlingfee.toString()).div("100"))).toFixed(parseInt(tokenadecimals.value));
            } else if (circulation.indexOf(tokena.value.toLowerCase()) != -1) {
              // 后扣
              b = true;
            }
          }

          let tx = await contract.getaost([tokena.value, tokenb.value], ethers.parseUnits(tokenaquantity.value, tokenadecimals.value), PancakeRouter.value, circulation);

          let Tokenbquantity = ethers.formatUnits(tx[0], tokenbdecimals.value);

          if (b) {
            Tokenbquantity = new Decimal(Tokenbquantity.toString()).sub(new Decimal(Tokenbquantity.toString()).mul(new Decimal(handlingfee.toString()).div("100"))).toFixed(parseInt(tokenbdecimals.value));
          }

          // 计算最小获得量
          amountInMax.value = new Decimal(Tokenbquantity.toString()).sub(new Decimal(Tokenbquantity.toString()).mul(new Decimal(slippage.value.form.Slippage.toString()).div("100"))).toFixed(parseInt(tokenbdecimals.value));

          if (Tokenbquantity / 1 > 0) {
            tokenbquantity.value = amountInMax.value;
            // 这里可以计算最少获得量
          } else {
            tokenbquantity.value = "0";
          }
          let plice = new Decimal("1").div(new Decimal(tokenaquantity.value).div(tokenbquantity.value)).toString();

          // 一个代币兑换的数量
          if (plice / 1 < 1) {
            plice = new Decimal(plice).toFixed(parseInt(tokenbdecimals.value));
          } else {
            plice = new Decimal(plice).toFixed(4);
          }
          if (plice / 1 <= 0) {
            plice = "0";
          }
          unitprice.value = "1 " + tokenasymbol.value.toUpperCase() + "≈" + plice + " " + tokenbsymbol.value.toUpperCase();
          if (unitprice.value.length > 40) {
            unitprice.value = unitprice.value.substring(0, 30) + "...";
          }
          path.value = [...tx[1]];
          loading.value = false;
        } else {
          unitprice.value = "";
          tokenbquantity.value = "0";
        }
      } catch (error) {
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else if (error.reason) {
          proxy.$message.error(error.reason);
          // 流动性不足
        }
      }
    };
    // 授权
    const Authorize = async () => {
      try {
        if (!isConnected.value) {
          proxy.$message.error(proxy.$t("dex.ConnectWallet"));
          return;
        }
        if (!Contract.value) {
          await getchanid();
        }
        loading.value = true;
        let abi = ["function approve(address spender, uint256 amount) external returns (bool)"];
        let contract = new ethers.Contract(tokena.value, abi, await getprovider().getSigner());
        let tx = await contract.approve(Contract.value, ethers.MaxUint256);
        await tx.wait();
        loading.value = false;
        isAuthorize();
      } catch (error) {
        loading.value = false;
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };
    // 交易
    const trade = async () => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }
      if (tokenaquantity.value <= 0) {
        proxy.$message.error(proxy.$t("dex.PleaseEnterAmount"));
        return;
      }

      try {
        loading.value = true;
        let abi = ["function DexcSwap(uint256 _amountOut,uint256 _amountInMax,address[] calldata _path,address _to,address _factory,address _acting,address[] calldata circulation)"];
        let contract = new ethers.Contract(Contract.value, abi, await getprovider().getSigner());
        let circulation = circulationlist.value.map((token) => token.address);
        let amountOut = ethers.parseUnits(tokenaquantity.value, tokenadecimals.value);
        let AmountInMax = ethers.parseUnits(amountInMax.value, tokenbdecimals.value);
        if (slippage.value.form.expert) {
          AmountInMax = "0";
        }
        // 然后才是交易
        let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
        let Tokenlist = data.concat(tokenlist.value);

        for (let i = 0; i < Tokenlist.length; i++) {
          if (tokena.value == Tokenlist[i].address) {
            if (Tokenlist[i].eth == "1") {
              let tx = await contract.DexcSwap(amountOut, AmountInMax, path.value, address.value, Factory.value, acting.value, circulation, {
                value: amountOut,
              });
              await tx.wait();
              proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
            } else {
              // 不是ETH

              let tx = await contract.DexcSwap(amountOut, AmountInMax, path.value, address.value, Factory.value, acting.value, circulation);
              await tx.wait();
              proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
            }
          }
        }
        Checkbalances();
        loading.value = false;
      } catch (error) {
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.message.error(error.message);
        }
        loading.value = false;
      }
    };
    watch(chainId, (newChainId, oldChainId) => {
      getdata();
      getchanid();
      // 在这里添加你的处理逻辑
    });
    onMounted(() => {
      if (isConnected.value) {
        getdata();
        getchanid();
      }
    });

    onUnmounted(() => {
      //
    });

    return {
      tokenlist,
      address,
      aswitch,
      bswitch,
      disabled,
      tokena,
      tokenb,
      tokenasymbol,
      tokenbsymbol,
      tokenaBalance,
      tokenbBalance,
      tokenaquantity,
      tokenbquantity,
      tokenswitch,
      Selectatoken,
      switcslippage,
      switchswitching,
      swapbox,
      slippage,
      chainId,
      isConnected,
      isAuthorize,
      Authorize,
      trade,
      getaost,
      Authorizeornot,
      loading,
      unitprice,
    };
  },
};
</script>

<style scoped>
.swapinputbox {
  flex: 1;
}
.swap {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
}

.swap .boxone {
  background: #1249ec;
  border-radius: 10px;
  height: 100px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  /* font-size: 16px; */
}
.swap .boxtwo {
  background: #121331;
  border-radius: 10px;
  border: 1px solid #33344e;
  height: 100px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* font-size: 16px; */
}

.swaptoken {
  display: flex;
  flex-wrap: nowrap;
}
.tokeninfo {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.banspan {
  padding: 0 11px;
  font-size: 15px;
  color: #ffff;
}
.unitpricefon {
  font-size: 14px;
  color: #b1b3b8;
}
.Settings {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;
}
.Settingsicon {
  font-size: 20px !important;
  color: #fff !important;
}
</style>
<style>
.box-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95vw;
  gap: 15px;
}

.swap .el-input__inner {
  --el-input-inner-height: none !important;
  font-size: 16px;
}

.swap .swapicon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: #040726;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  border: 4px solid #040726;
  transform: translateY(-50%);
  right: 50%;
  z-index: 2;
}
.swap .el-input {
  width: 60vw;
}
@media (min-width: 600px) {
  .box-card {
    width: 600px !important;
  }
}
@media (min-width: 750px) {
  .swap .el-input {
    width: 450px !important;
  }
}
</style>
<style>
.swap .boxone .el-input__wrapper {
  box-shadow: none !important;
  height: 40px !important;
  background-color: #1249ec !important;
}
.swap .boxtwo .el-input__wrapper {
  box-shadow: none !important;
  height: 40px !important;
  background-color: #121331 !important;
}
.swaptoken .el-input__inner {
  color: #89a4f5 !important;
  font-size: 16px !important;
  font-weight: bold;
}
button:disabled {
  color: #fff !important;
}
</style>
<!-- 使用签名(收款人(中继者地址 nonce)   存款 存款人地址(nonce)(代币)存储数量  ) -->
<!-- 取款  通过中继者地址后端取款  用户无需链接钱包    使用签名凭证(包含了nonce 收款人地址)   用户只需要填写凭证  收款地址  即可通过中继者取出款项-->
