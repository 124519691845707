<template>
  <div>
    <div class="box-card">
      <div class="swap">
        <div class="Linkwallet" v-if="!isConnected">
          <w3m-button size="sm" />
        </div>
        <el-icon class="swapicon" @click="tokenswitch">
          <DCaret />
        </el-icon>
        <div class="boxone">
          <div class="banspan">{{ $t("dex.Balance") + tokenaBalance }}</div>
          <div class="swaptoken">
            <el-input class="inputfont" :disabled="disabled" v-model="tokenaquantity" @input="getaost('a')" :placeholder="$t('dex.PleaseEnterSwapAmount')" />
            <div class="tokeninfo" @click="Selectatoken('a')">
              <el-avatar class="tokenlistlogo" size="small" :src="tokena ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + tokena.toLowerCase() + '.png' : ''" />
              <span>{{ tokenasymbol.toUpperCase() }}</span>
              <el-icon><ArrowRight v-if="!aswitch" /> <ArrowDown v-else /></el-icon>
            </div>
          </div>
        </div>
        <div class="boxtwo">
          <div class="banspan">{{ $t("dex.Balance") + tokenbBalance }}</div>
          <div class="swaptoken">
            <el-input class="inputfont" :disabled="disabled" v-model="tokenbquantity" @input="getaost('b')" :placeholder="$t('dex.PleaseEnterSwapAmount')" />
            <div class="tokeninfo" @click="Selectatoken('b')">
              <el-avatar class="tokenlistlogo" size="small" :src="tokenb ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + tokenb.toLowerCase() + '.png' : ''" />
              <span>{{ tokenbsymbol.toUpperCase() }}</span>
              <el-icon><ArrowRight v-if="!bswitch" /> <ArrowDown v-else /></el-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="Settings">
        <span class="unitpricefon">{{ unitprice }}</span>
        <el-icon class="Settingsicon" @click="switcslippage">
          <Tools />
        </el-icon>
      </div>
      <el-button v-if="!isConnected" :disabled="true" type="primary">{{ $t("dex.ConnectWallet") }}</el-button>
      <el-button v-else-if="tokenaquantity == 0 || tokenbquantity == 0" :disabled="true" type="primary">{{ $t("dex.PleaseEnterAddAmount") }}</el-button>
      <el-button v-else-if="tokenaBalance / 1 < tokenaquantity / 1 || tokenbquantity / 1 > tokenbBalance / 1" :disabled="true" type="primary">{{ $t("dex.InsufficientBalance") }}</el-button>
      <el-button v-else-if="!Authorizeornot || !Authorizeornotb" type="primary" :loading="loading" @click="Authorize(Authorizeaddress)">{{ $t("dex.Authorization") + Authtokenname }}</el-button>
      <el-button v-else type="primary" :loading="loading" @click="trade">{{ $t("dex.addliquidity") }}</el-button>
    </div>
    <Swapbox ref="swapbox" @sendData="switchswitching" />
    <slippage ref="slippage" @sendData="getaost('a')" />
  </div>
</template>

<script>
import { watch } from "vue";
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import Swapbox from "@/components/swap/box";
import slippage from "@/components/swap/Slippage";
import { axiosInstance } from "@/api/post";
import { ethers } from "ethers";
import { getprovider, getEthBalance, Checktokenbalance, truncateDecimal } from "@/api/wallet";
import { useWeb3ModalAccount } from "@web3modal/ethers/vue";
import Decimal from "decimal.js";

export default {
  name: "addliquidity",
  components: {
    Swapbox,
    slippage,
  },
  setup() {
    const loading = ref(false);
    const { proxy } = getCurrentInstance();
    const tokenlist = ref([]);
    const aswitch = ref(false);
    const bswitch = ref(false);
    const disabled = ref(false);
    const tokena = ref("");
    const tokenb = ref("");
    const tokenasymbol = ref("");
    const tokenbsymbol = ref("");
    const tokenaBalance = ref("0");
    const tokenbBalance = ref("0");
    const tokenaquantity = ref("0");
    const tokenbquantity = ref("0");
    const Authorizeornot = ref(false);
    const Contract = ref(null);
    const PancakeRouter = ref(null);
    const Factory = ref(null);
    const tokenadecimals = ref(null);
    const tokenbdecimals = ref(null);
    const amountInMax = ref(null);
    const unitprice = ref(null);
    const path = ref([]);
    const circulationlist = ref([]);
    const acting = ref("0x0000000000000000000000000000000000000000");
    const Authtokenname = ref(null);
    const Authorizeaddress = ref(null);
    const Authorizeornotb = ref(null);

    const swapbox = ref(null); // 使用 ref 来引用 Swapbox 组件
    const slippage = ref(null);
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const tokenswitch = () => {
      [tokena.value, tokenb.value] = [tokenb.value, tokena.value];
      if (tokena.value && tokenb.value && address.value) {
        getaost("a");
      }
    };

    const Selectatoken = (ele) => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }
      if (ele === "a") {
        aswitch.value = true;
      } else {
        bswitch.value = true;
      }
      // 访问子组件的方法
      swapbox.value.boxswitch(ele);
    };

    const switcslippage = () => {
      slippage.value.boxswitch();
    };

    const switchswitching = (address, symbol, tokensel) => {
      if (tokensel === "a") {
        if (address && symbol) {
          tokena.value = address;
          tokenasymbol.value = symbol;
          getaost("a");
        }
        aswitch.value = false;
      } else {
        if (address && symbol) {
          tokenb.value = address;
          tokenbsymbol.value = symbol;
          getaost("b");
        }
        bswitch.value = false;
      }
    };

    const getchanid = async () => {
      let response = await axiosInstance.post("Query/chain", { chain: Number(chainId.value) });
      response = response.data;
      if (response.code == 200) {
        Contract.value = response.data.contract;
        Factory.value = response.data.Factory;
        PancakeRouter.value = response.data.PancakeRouter;
      }
    };

    const getdata = async () => {
      try {
        if (!isConnected.value) {
          chainId.value = 56;
        }
        let response = await axiosInstance.post("Query/tokenlist", { chain: chainId.value });

        response = response.data;
        if (response.code === 200) {
          let list = response.data;
          circulationlist.value = [...list];
          tokenasymbol.value = list[0] ? list[0].symbol : "";
          tokenbsymbol.value = list[1] ? list[1].symbol : "";
          tokena.value = list[0] ? list[0].address : "";
          tokenb.value = list[0] ? list[1].address : "";

          tokenlist.value = list;
          Checkbalances();
        }
      } catch (error) {
        proxy.$message.error(proxy.$t("dex.networkError"));
      }
    };

    const Checkbalances = async () => {
      try {
        if (!address.value) {
          return;
        }
        loading.value = true;
        let data = await proxy.$indexedDB.getObjectsByChain(chainId.value);
        let tokenlistData = data.concat(tokenlist.value);

        for (let i = 0; i < tokenlistData.length; i++) {
          if (tokena.value === tokenlistData[i].address) {
            tokenasymbol.value = tokenlistData[i].symbol;
            if (tokenlistData[i].eth === "1") {
              tokenaBalance.value = truncateDecimal(await getEthBalance(address.value));
            } else {
              tokenaBalance.value = truncateDecimal(await Checktokenbalance(address.value, tokena.value));
            }
          }

          if (tokenb.value === tokenlistData[i].address) {
            tokenbsymbol.value = tokenlistData[i].symbol;
            if (tokenlistData[i].eth === "1") {
              tokenbBalance.value = truncateDecimal(await getEthBalance(address.value));
            } else {
              tokenbBalance.value = truncateDecimal(await Checktokenbalance(address.value, tokenb.value));
            }
          }
        }
        loading.value = false;
        isAuthorize();
      } catch (error) {
        console.error(error.message);
      }
    };

    // 检查授权
    const isAuthorize = async () => {
      try {
        // 先要判断是不是bnb

        if (!isConnected.value) {
          return;
        }
        // 先要判断是不是bnb
        loading.value = true;
        let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
        let Tokenlist = data.concat(tokenlist.value);

        for (let i = 0; i < Tokenlist.length; i++) {
          if (tokena.value == Tokenlist[i].address) {
            if (Tokenlist[i].eth == "1") {
              Authorizeornot.value = true;
            } else {
              if (tokenaquantity.value > 0 && address.value) {
                // 这个要第一个input 输入了数字 才触发
                let abi = ["function decimals() view returns (uint8)", " function allowance(address owner,address spender) external view returns (uint256)"];
                let contract = new ethers.Contract(tokena.value, abi, getprovider());
                let allowance = await contract.allowance(address.value, Contract.value);
                // 获取当前代币精度
                let decimals = await contract.decimals();
                let balanceOf = ethers.formatUnits(allowance, decimals);

                if (balanceOf / 1 >= tokenaquantity.value / 1) {
                  // 无需授权
                  Authorizeornot.value = true;
                } else {
                  // 需要授权
                  Authtokenname.value = "tokena";
                  Authorizeaddress.value = tokena.value;
                  Authorizeornot.value = false;
                }
              }
            }
          }
          if (Authorizeornot.value && tokenb.value == Tokenlist[i].address) {
            if (Tokenlist[i].eth == "1") {
              Authorizeornotb.value = true;
            } else {
              if (tokenbquantity.value > 0 && address.value) {
                // 这个要第一个input 输入了数字 才触发
                let abi = ["function decimals() view returns (uint8)", " function allowance(address owner,address spender) external view returns (uint256)"];
                let contract = new ethers.Contract(tokenb.value, abi, getprovider());
                let allowance = await contract.allowance(address.value, Contract.value);
                // 获取当前代币精度
                let decimals = await contract.decimals();
                let balanceOf = ethers.formatUnits(allowance, decimals);

                if (balanceOf / 1 >= tokenbquantity.value / 1) {
                  // 无需授权
                  Authorizeornotb.value = true;
                } else {
                  // 需要授权
                  Authtokenname.value = "tokenb";
                  Authorizeaddress.value = tokenb.value;
                  Authorizeornotb.value = false;
                }
              }
            }
          }
        }
        loading.value = false;
      } catch (error) {
        loading.value = false;
        console.log(error.message);
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };
    // 查询交易路径
    const getaost = async (ele) => {
      try {
        if (!isConnected.value) {
          proxy.$message.error(proxy.$t("dex.ConnectWallet"));
          return;
        }
        if (!Contract.value) {
          await getchanid();
        }
        await Checkbalances();
        let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
        let Tokenlist = data.concat(tokenlist.value);

        let circulation = Tokenlist.map((token) => token.address.toLowerCase());

        let abi = ["function rate() public view returns (uint)", "function getLiquidity(address tokenA,address tokenB,uint amountADesired,address swapV2Router) public view returns (uint)", "function getaost(address[] calldata _path,uint256 _autom,address _addr,address[] calldata circulation) public view returns (uint256, address[] memory, string[] memory)", "function decimals() view returns (uint8)"];
        let contract = new ethers.Contract(tokena.value, abi, getprovider());
        tokenadecimals.value = await contract.decimals();
        contract = new ethers.Contract(tokenb.value, abi, getprovider());
        tokenbdecimals.value = await contract.decimals();
        // 获取代币池子内代币ab 数量
        contract = new ethers.Contract(Contract.value, abi, await getprovider());

        if (ele == "a" && tokenaquantity.value > 0) {
          let Tokenaquantitys = tokenaquantity.value;
          //   只要有一个是ETH  就不要再检测第二个
          let continueis = false;
          let b = false;
          let handlingfee = await contract.rate();
          for (let i = 0; i < tokenlist.value.length; i++) {
            if (tokena.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
              //tokena  是ETH 开始就扣掉手续费
              Tokenaquantitys = new Decimal(Tokenaquantitys.toString()).sub(new Decimal(Tokenaquantitys.toString()).mul(handlingfee.toString()).div("100")).toFixed(parseInt(tokenadecimals.value));
              continueis = true;
            } else if (tokenb.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
              // teokenb 是eth 计算完成后 增加
              continueis = true;
              b = true;
            }
          }
          if (!continueis) {
            if (circulation.indexOf(tokena.value.toLowerCase()) != -1) {
              // tikena 是主流币
              Tokenaquantitys = new Decimal(Tokenaquantitys.toString()).sub(new Decimal(Tokenaquantitys.toString()).mul(handlingfee.toString()).div("100")).toFixed(parseInt(tokenadecimals.value));
            } else if (circulation.indexOf(tokenb.value.toLowerCase()) != -1) {
              // tokenb是主流币
              b = true;
            }
          }

          let Tokenbquantity = await contract.getLiquidity(tokena.value, tokenb.value, ethers.parseUnits(Tokenaquantitys, tokenadecimals.value), PancakeRouter.value);

          if (ethers.formatUnits(Tokenbquantity, tokenbdecimals.value) > 0) {
            if (b) {
              Tokenbquantity = new Decimal(Tokenbquantity.toString()).div(new Decimal("1").sub(new Decimal(handlingfee.toString()).div("100"))).toFixed(0);
            }
            tokenbquantity.value = ethers.formatUnits(Tokenbquantity, tokenbdecimals.value);
          }
        } else if (ele == "b" && tokenbquantity.value > 0) {
          let Tokenaquantitys = tokenbquantity.value;
          //   只要有一个是ETH  就不要再检测第二个
          let continueis = false;
          let b = false;
          let handlingfee = await contract.rate();
          for (let i = 0; i < tokenlist.value.length; i++) {
            if (tokenb.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
              //tokena  是ETH 开始就扣掉手续费
              Tokenaquantitys = new Decimal(Tokenaquantitys.toString()).sub(new Decimal(Tokenaquantitys.toString()).mul(handlingfee.toString()).div("100")).toFixed(parseInt(tokenadecimals.value));
              continueis = true;
            } else if (tokena.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
              // teokenb 是eth 计算完成后  扣掉手续费
              continueis = true;
              b = true;
            }
          }
          if (!continueis) {
            if (circulation.indexOf(tokena.value.toLowerCase()) != -1) {
              // tikena 是主流币
              b = true;
            } else if (circulation.indexOf(tokenb.value.toLowerCase()) != -1) {
              // tokenb是主流币
              tokenaquantitys = new Decimal(Tokenaquantitys.toString()).sub(new Decimal(Tokenaquantitys.toString()).mul(handlingfee.toString()).div("100")).toFixed(parseInt(tokenadecimals.value));
            }
          }

          // 这里同样需要判断代币A 是不是主流币
          let Tokenaquantity = await contract.getLiquidity(tokenb.value, tokena.value, ethers.parseUnits(Tokenaquantitys, tokenbdecimals.value), PancakeRouter.value);
          if (ethers.formatUnits(Tokenaquantity, tokenadecimals.value) > 0) {
            if (b) {
              Tokenaquantity = new Decimal(Tokenaquantity.toString()).div(new Decimal("1").sub(new Decimal(handlingfee.toString()).div("100"))).toFixed(0);
            }
            tokenaquantity.value = ethers.formatUnits(Tokenaquantity, tokenadecimals.value);
          }
        }
      } catch (error) {
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else if (error.reason) {
          proxy.$message.error(error.reason);
          // 流动性不足
        }
      }
      isAuthorize();
    };
    // 授权
    const Authorize = async (address) => {
      try {
        if (!isConnected.value) {
          proxy.$message.error(proxy.$t("dex.ConnectWallet"));
          return;
        }
        if (!Contract.value) {
          await getchanid();
        }

        loading.value = true;
        let abi = ["function approve(address spender, uint256 amount) external returns (bool)"];
        let contract = new ethers.Contract(address, abi, await getprovider().getSigner());
        let tx = await contract.approve(Contract.value, ethers.MaxUint256);
        await tx.wait();
        loading.value = false;
        isAuthorize();
      } catch (error) {
        loading.value = false;
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;
          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
      }
    };
    // 交易
    const trade = async () => {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t("dex.ConnectWallet"));
        return;
      }
      if (tokenaquantity.value <= 0 || tokenbquantity.value <= 0) {
        proxy.$message.error(proxy.$t("dex.PleaseEnterAddAmount"));
        return;
      }

      try {
        loading.value = true;
        let continuei = false;
        let abi = ["function rate() public view returns (uint)"];
        let contract = new ethers.Contract(Contract.value, abi, await getprovider());
        let handlingfee = await contract.rate();
        abi = ["function addLiquidity( address tokenA,address tokenB,uint amountADesired,uint amountBDesired,uint amountAMin,uint amountBMin,address to,address swapV2Router,bool bur)", "function addLiquidityETH(address _token,uint amountTokenDesired,uint amountTokenMin,uint amountETHMin, address to,address swapV2Router)"];
        contract = new ethers.Contract(Contract.value, abi, await getprovider().getSigner());
        let data = await proxy.$indexedDB.getObjectsByChain(Number(chainId.value));
        let Tokenlist = data.concat(tokenlist.value);

        let circulation = Tokenlist.map((token) => token.address.toLowerCase());
        let amountAMin = ethers.parseUnits(
          new Decimal(tokenaquantity.value.toString())
            .sub(new Decimal(tokenaquantity.value.toString()).mul(new Decimal(handlingfee.toString()).add(slippage.value.form.Slippage.toString())).div("100"))
            .toFixed(parseInt(tokenadecimals.value))
            .toString(),
          tokenadecimals.value
        );
        let amountBMin = ethers.parseUnits(
          new Decimal(tokenbquantity.value.toString())
            .sub(new Decimal(tokenbquantity.value.toString()).mul(new Decimal(handlingfee.toString()).add(slippage.value.form.Slippage.toString())).div("100"))
            .toFixed(parseInt(tokenbdecimals.value))
            .toString(),
          tokenbdecimals.value
        );

        for (let i = 0; i < Tokenlist.length; i++) {
          // tokena
          if (tokena.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
            continuei = true;
            //tokena是 ETH
            let tx = await contract.addLiquidityETH(tokenb.value, ethers.parseUnits(tokenbquantity.value, tokenbdecimals.value), amountBMin, amountAMin, address.value, PancakeRouter.value, {
              value: ethers.parseUnits(tokenaquantity.value, tokenadecimals.value),
            });
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          } else if (tokenb.value == Tokenlist[i].address && Tokenlist[i].eth == "1") {
            //tokenb ETH
            continuei = true;
            let tx = await contract.addLiquidityETH(tokena.value, ethers.parseUnits(tokenaquantity.value, tokenadecimals.value), amountAMin, amountBMin, address.value, PancakeRouter.value, {
              value: ethers.parseUnits(tokenbquantity.value, tokenbdecimals.value),
            });
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          }
        }
        // 在这里处理不是eth的情况
        if (!continuei) {
          if (circulation.indexOf(tokena.value.toLowerCase()) != -1) {
            // tikena 是主流币

            let tx = await contract.addLiquidity(tokenb.value, tokena.value, ethers.parseUnits(tokenbquantity.value, tokenbdecimals.value), ethers.parseUnits(tokenaquantity.value, tokenadecimals.value), amountBMin, amountAMin, address.value, PancakeRouter.value, true);
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          } else if (circulation.indexOf(tokenb.value.toLowerCase()) != -1) {
            // tokenb是主流币
            let tx = await contract.addLiquidity(tokena.value, tokenb.value, ethers.parseUnits(tokenaquantity.value, tokenadecimals.value), ethers.parseUnits(tokenbquantity.value, tokenbdecimals.value), amountAMin, amountBMin, address.value, PancakeRouter.value, true);
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          } else {
            // 都不是主流币
            let tx = await contract.addLiquidity(tokena.value, tokenb.value, ethers.parseUnits(tokenaquantity.value, tokenadecimals.value), ethers.parseUnits(tokenbquantity.value, tokenbdecimals.value), amountAMin, amountBMin, address.value, PancakeRouter.value, false);
            await tx.wait();
            proxy.$message.success(proxy.$t("dex.transactionSuccessful"));
          }
        }

        loading.value = false;
      } catch (error) {
        console.log(error.message);
        if (error.info && error.info.error) {
          const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message;

          proxy.$message.error(errorMessage);
        } else {
          proxy.$message.error(error.message);
        }
        loading.value = false;
      }
    };

    watch(chainId, (newChainId, oldChainId) => {
      getdata();
      getchanid();
      // 在这里添加你的处理逻辑
    });
    onMounted(() => {
      if (isConnected.value) {
        getdata();
        getchanid();
      }
    });

    onUnmounted(() => {
      //
    });

    return {
      tokenlist,
      address,
      aswitch,
      bswitch,
      disabled,
      tokena,
      tokenb,
      tokenasymbol,
      tokenbsymbol,
      tokenaBalance,
      tokenbBalance,
      tokenaquantity,
      tokenbquantity,
      tokenswitch,
      Selectatoken,
      switcslippage,
      switchswitching,
      swapbox,
      slippage,
      chainId,
      isConnected,
      isAuthorize,
      Authorize,
      trade,
      getaost,
      Authorizeornot,
      loading,
      unitprice,
      Authtokenname,
      Authorizeornotb,
      Authorizeaddress,
    };
  },
};
</script>

<style scoped>
.swapinputbox {
  flex: 1;
}
.swap {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
}

.swap .boxone {
  background: #1249ec;
  border-radius: 10px;
  height: 100px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  /* font-size: 16px; */
}
.swap .boxtwo {
  background: #121331;
  border-radius: 10px;
  border: 1px solid #33344e;
  height: 100px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* font-size: 16px; */
}

.swaptoken {
  display: flex;
  flex-wrap: nowrap;
}
.tokeninfo {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #ffff;
}
.banspan {
  padding: 0 11px;
  font-size: 15px;
  color: #ffff;
}
.unitpricefon {
  font-size: 14px;
  color: #b1b3b8;
}
.Settings {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;
}
</style>
<style>
.box-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95vw;
  gap: 15px;
}
.Settingsicon {
  font-size: 20px !important;
  color: #fff !important;
}
.swap .el-input__inner {
  --el-input-inner-height: none !important;
  font-size: 16px;
}

.swap .swapicon {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  color: #040726;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  border: 4px solid #040726;
  transform: translateY(-50%);
  right: 50%;
  z-index: 2;
}
.swap .el-input {
  width: 60vw;
}
@media (min-width: 600px) {
  .box-card {
    width: 600px !important;
  }
}
@media (min-width: 750px) {
  .swap .el-input {
    width: 450px !important;
  }
}
</style>
<style>
.swap .boxone .el-input__wrapper {
  box-shadow: none !important;
  height: 40px !important;
  background-color: #1249ec !important;
}
.swap .boxtwo .el-input__wrapper {
  box-shadow: none !important;
  height: 40px !important;
  background-color: #121331 !important;
}
</style>
