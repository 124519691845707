<template>
  <div>
    <el-dialog class="dialog" v-model="dialogVisible" :title="$t('dex.settings')" :before-close="handleClose" width="350px">
      <el-form :model="form" label-position="top" label-width="50px">
        <el-form-item>
          <template #label>
            <div class="label">
              <span>{{ $t("dex.SlippageLimit") }}</span>
              <el-tooltip effect="dark" content="Top Left prompts info" placement="top-start">
                <el-icon>
                  <InfoFilled />
                </el-icon>
              </el-tooltip>
            </div>
          </template>
          <el-check-tag :checked="form.Slippage == 0.1" style="margin-right: 8px" @click="form.Slippage = 0.1">0.1%</el-check-tag>
          <el-check-tag :checked="form.Slippage == 0.5" style="margin-right: 8px" @click="form.Slippage = 0.5">0.5%</el-check-tag>
          <el-check-tag :checked="form.Slippage == 1" style="margin-right: 8px" @click="form.Slippage = 1">1.0%</el-check-tag>
          <el-input style="width: 80px" v-model="form.Slippage">
            <template #append>%</template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-form :model="form">
        <el-form-item>
          <template #label>
            <div class="label">
              <span>{{ $t("dex.ExpertMode") }}</span>
              <el-tooltip effect="dark" :content="$t('dex.BypassConfirmationAndAllowHighSlippage')" placement="top-start">
                <el-icon>
                  <InfoFilled />
                </el-icon>
              </el-tooltip>
            </div>
          </template>
          <el-switch v-model="form.expert" />
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "slippage",

  data() {
    return {
      dialogVisible: false,
      form: {
        Slippage: 2,
        expert: false,
      },
    };
  },
  methods: {
    boxswitch() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("sendData");
    },
    //
  },
  mounted() {
    // this.ConnectWallet()
  },
};
</script>

<style>
.dialog .el-input-group__append,
.dialog .el-input-group__prepend {
  padding: 0 10px !important;
}
</style>
