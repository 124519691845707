// src/web3ModalConfig.ts
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/vue';

const projectId = '5f931bf56115df8e871c07ad48050535';

// 2. Set chains



const BSCTestnet = {
  chainId: 97,
  name: 'BSC Testnet',
  currency: 'tBNB',
  explorerUrl: 'https://testnet.bscscan.com',
  rpcUrl: 'https://go.getblock.io/e7ed2ba282b448c69d94df588c9cae9c',
}

const EthereumMainne = {
  chainId: 1,
  name: 'Ethereum Mainne',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://go.getblock.io/d94190e426e14f9bb70037a893f0536d',
}

const Arbitrum = {
  chainId: 42161,
  name: 'Arbitrum One',
  currency: 'ETH',
  explorerUrl: 'https://explorer.arbitrum.io',
  rpcUrl: 'https://go.getblock.io/81901cf4aae84d6c8a98fe8a9fa87cd5',
}


const BNBChain = {
  chainId: 56,
  name: 'BNB Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: 'https://go.getblock.io/e493886b71c24f119d8579b0cfc19250',
}

const Polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://go.getblock.io/1cf2721544cd490c926851f6b77d1562',
};


// 3. Create your application's metadata object
const metadata = {
  name: 'DEXC TOOL',
  description: 'My Website description',
  url: 'https://dexctool.onrender.com/', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: false,
  defaultChainId: 137, // Coinbase SDK Default Chain ID
  auth: {
    email: false, // default to true
  }
});

// 5. Create a Web3Modal instance

const modal = createWeb3Modal({
  ethersConfig,
  chains: [EthereumMainne, BNBChain, Polygon, Arbitrum, BSCTestnet],
  allowUnsupportedChain: false,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
  featuredWalletIds: [//主要试图
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //小狐狸
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',//BIT
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', //扫码
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',//BIT
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',//TP钱包
  ],
  // 更多页面显示
  // includeWalletIds: [
  //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //小狐狸
  //   '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',//TP钱包
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', //扫码
  //   '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662' //BIT
  // ],
  // excludeWalletIds: [
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',//PAICHU
  //   'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  // ]

});





export { modal };
