import { App, Plugin } from 'vue';
import IndexedDBManager from './db'; // 假设文件路径是正确的

// 导出一个 Vue 插件对象
const indexedDBPlugin: Plugin = {

  install: (app: App) => {
    const dbName = 'token'; // 替换为您的数据库名称
    const storeName = 'tokenlist'; // 替换为您的存储空间名称
    app.config.globalProperties.$indexedDB = new IndexedDBManager(dbName, storeName);
  }
};

export default indexedDBPlugin;