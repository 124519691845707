<template>
  <div>
    <myfooter />
    <div class="mains">
      <MYcar />
    </div>
    <MYnav />
  </div>
</template>

<script>
import myfooter from "@/components/myfooter";
import MYcar from "@/components/car";
import MYnav from "@/components/nav";

export default {
  components: {
    myfooter,
    MYnav,
    MYcar,
  },
};
</script>
<style scoped>
.mains {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* 垂直居中 */
  /* margin: 3vh 0; */
  /* min-height: 100vh; */
  /* height: 100vh; */

  padding-bottom: 17vh;
}
</style>
<style>
.mains .el-statistic__head {
  color: #fff;
  font-size: 3vh;
  font-weight: bold;
}
</style>

<!-- 1 swap  发币  -->
