import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '../views/IndexView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: IndexView,
    meta: { title: 'Home - DEXCTOOL - mintswapmixer' }  // 设置标题
  },
  {
    path: '/mint',
    name: 'mint',
    component: () => import( '../views/mint.vue'),
    meta: { title: 'Mint - DEXCTOOL - mintswapmixer' }  // 设置标题

  },
  {
    path: '/mixezs',
    name: 'Mixezs',
    component: () => import( '../views/Mixezs.vue'),
    meta: { title: 'mixezs - DEXCTOOL - mintswapmixer' }  // 设置标题
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})
router.beforeEach((to, from, next) => {
  const title = (to.meta.title as string) || 'Default Title';
  document.title = title;
  next();
});
export default router
