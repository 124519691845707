<template>
  <div class="nav">
    <div class="list">
      <router-link class="box" to="/">
        <el-icon><HomeFilled /></el-icon>
        <span>{{ $t("nav.swap") }}</span>
      </router-link>
      <router-link class="box" to="/mint">
        <el-icon><HelpFilled /></el-icon>
        <span>{{ $t("nav.mint") }}</span>
      </router-link>
      <router-link class="box" to="/mixezs">
        <el-icon><Histogram /></el-icon>
        <span>{{ $t("nav.Mixezs") }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MYnav",
  components: {},
  data() {
    return {
      defaultchanid: "0",
    };
  },
  methods: {
    //
  },
  mounted() {
    //
  },
};
</script>
<style scoped>
.nav {
  width: 100vw;
  position: fixed;
  bottom: 0.34rem !important;
  z-index: 99;
  /* opacity: 0.3; */
}
.nav .list {
  width: 3rem;
  height: 0.54rem;
  color: aliceblue;
  background: #121331;
  border-radius: 0.24rem;
  display: flex;
  margin: auto;
  justify-content: space-around;
}
.nav .box {
  flex: 1; /* 每个子元素等分空间 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.05rem;
  font-size: 0.16rem;
  font-weight: bold;
}
.router-link-active {
  background: #1249ec;
  border-radius: 0.24rem;
}

@media (min-width: 550px) {
  .nav {
    bottom: 49.776px !important;
    /* opacity: 0.3; */
  }
  .nav .list {
    width: 439.188px;
    height: 79.0469px;

    border-radius: 35.13px;
  }
  .nav .box {
    gap: 7.32px;
    font-size: 23.424px;
    font-weight: bold;
  }
  .router-link-active {
    border-radius: 35.13px;
  }
}
</style>
