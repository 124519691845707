class IndexedDBManager {
  private dbName: string;
  private storeName: string;
  private db: IDBDatabase | null;

  constructor(dbName: string, storeName: string) {
    this.dbName = dbName;
    this.storeName = storeName;
    this.db = null;
  }

  private ensureDBIsOpen(): Promise<void> {

    return new Promise((resolve, reject) => {
      if (this.db) {
        resolve();
      } else {
        const request = indexedDB.open(this.dbName);
        request.onerror = (event) => {
          // console.error('打开数据库出错:', (event.target as IDBRequest<IDBDatabase>).error);
          resolve(); // Return without rejecting to avoid throwing an error
        };

        request.onsuccess = (event) => {
          this.db = (event.target as IDBRequest<IDBDatabase>).result;
          resolve();
        };

        request.onupgradeneeded = (event) => {
          this.db = (event.target as IDBOpenDBRequest).result as IDBDatabase;
          if (!this.db.objectStoreNames.contains(this.storeName)) {
            const objectStore =   this.db.createObjectStore(this.storeName, { keyPath: 'id', autoIncrement: true });
            objectStore.createIndex('address', 'address', { unique: true });
            objectStore.createIndex('chain', 'chain', { unique: false });
          }
        };
      }
    });
  }

  public async addObject(data: any): Promise<boolean> {
    await this.ensureDBIsOpen();
    const transaction = this.db?.transaction([this.storeName], 'readwrite');
    const objectStore = transaction?.objectStore(this.storeName);

    if (objectStore) {
      const request = objectStore.add(data);
      return new Promise((resolve) => {
        request.onsuccess = () => {
          resolve(true);
        };
        request.onerror = () => {
          resolve(false);
        };
      });
    } else {

      return false;
    }
  }

  public async getObject(id: number): Promise<any | undefined> {
    await this.ensureDBIsOpen();
    const transaction = this.db?.transaction([this.storeName], 'readonly');
    const objectStore = transaction?.objectStore(this.storeName);

    if (objectStore) {
      const request = objectStore.get(id);

      return new Promise((resolve) => {
        request.onsuccess = (event) => {
          const data = (event.target as IDBRequest).result;
          if (data) {

            resolve(data);
          } else {

            resolve(undefined);
          }
        };

        request.onerror = () => {

          resolve(undefined);
        };
      });
    } else {

      return undefined;
    }
  }


// 通过地址查询
  public async getObjectByAddress(address: string): Promise<any | undefined> {
    await this.ensureDBIsOpen();
    if (!this.db) {
      return false;
    }
    const transaction = this.db.transaction([this.storeName], 'readonly');
    const objectStore = transaction.objectStore(this.storeName);

    const index = objectStore.index('address'); // 获取 address 索引
    const request = index.get(address); // 根据 address 查询

    return new Promise((resolve, reject) => {
      request.onsuccess = (event) => {
        const data = (event.target as IDBRequest).result;
        resolve(data || undefined);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
// 通过chanid 查询所有
public async getObjectsByChain(chain: number): Promise<any[]> {
  await this.ensureDBIsOpen();

  if (!this.db) {

    return [];
  }
  const transaction = this.db.transaction([this.storeName], 'readonly');
  const objectStore = transaction.objectStore(this.storeName);

  // 确保索引存在
  if (!objectStore.indexNames.contains('chain')) {

    return [];
  }

  const index = objectStore.index('chain');
  const request = index.openCursor(IDBKeyRange.only(chain));

  const results: any[] = [];

  return new Promise((resolve, reject) => {
    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
      if (cursor) {

        results.push(cursor.value);
        cursor.continue(); // 继续遍历
      } else {
        resolve(results);
      }
    };

    request.onerror = (event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}



  public async getAllObjects(): Promise<any[]> {
    await this.ensureDBIsOpen();
    const transaction = this.db?.transaction([this.storeName], 'readonly');
    const objectStore = transaction?.objectStore(this.storeName);

    if (objectStore) {
      const request = objectStore.getAll();

      return new Promise((resolve) => {
        request.onsuccess = (event) => {
          const data = (event.target as IDBRequest).result;

          resolve(data);
        };

        request.onerror = () => {

          resolve([]);
        };
      });
    } else {

      return [];
    }
  }

  public async updateObject(data: any): Promise<boolean> {
    await this.ensureDBIsOpen();
    const transaction = this.db?.transaction([this.storeName], 'readwrite');
    const objectStore = transaction?.objectStore(this.storeName);

    if (objectStore) {
      const request = objectStore.put(data);

      return new Promise((resolve) => {
        request.onsuccess = () => {

          resolve(true);
        };

        request.onerror = () => {

          resolve(false);
        };
      });
    } else {

      return false;
    }
  }

  public async deleteObject(id: number): Promise<boolean> {
    await this.ensureDBIsOpen();
    const transaction = this.db?.transaction([this.storeName], 'readwrite');
    const objectStore = transaction?.objectStore(this.storeName);

    if (objectStore) {
      const request = objectStore.delete(id);

      return new Promise((resolve) => {
        request.onsuccess = () => {

          resolve(true);
        };

        request.onerror = () => {
          resolve(false);
        };
      });
    } else {

      return false;
    }
  }
  // 删除数据库
  public deleteDatabase(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(this.dbName);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };

      request.onblocked = () => {
        console.log(`删除数据库 ${this.dbName} 被阻塞`);
      };
    });
  }
}

export default IndexedDBManager;